@import url('colors.css');

.dives-bloc {
	display: flex;
	flex-direction: row;
	margin-top: 45px;
	z-index: 1000;
}

.dives-tabs {
	padding: 8px;
	text-align: center;
	font-size: 14px;
	font-weight: 300;
	width: 15.5%;
	background-color: var(--primary);
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	z-index: 1000;
}
.dives-tabs:not(:last-child) {
	border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
	background: white;
	border-bottom: 1px solid transparent;
	color: black;
}

.active-tabs::before {
	content: '';
	display: block;
	position: absolute;
	top: -5px;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + 2px);
	height: 5px;
	background: var(--secondary);
}

.dives-content-tabs {
	flex-grow: 1;
}
.dives-content {
	background: white;
	padding: 35px;
	width: 80%;
	display: none;
	z-index: 1000;
}

.dives-container {
	margin-left: -15px;
	z-index: 1000;
	height: 100%;
	overflow: hidden;
}

.active-content {
	display: block;
	z-index: 1000;
	height: 55vh;
	overflow-y: scroll;
}
.dm-dif-div {
	background-color: var(--light);
	border-radius: 2rem;
	margin-left: 50px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: right;
	align-items: right;
}

.dm-num {
	margin-top: -10px;
	margin-left: 10px;
	text-decoration-line: underline;
}

.dive {
	font-size: 17px;
	font-weight: 300;
}

.dm-difficutly {
	margin: 10px 30px;
}

.dm-display {
	border: 3px solid var(--primary);
	border-radius: 2rem;
	padding: 10px;
	margin: 5px 0px;
	max-height: 600px;
}

.dm-h1 {
	font-weight: 300;
	font-size: 40px;
}

.dm-h3 {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 300;
}

.twist {
	background-color: red;
}

.dive-modal-head {
	display: flex;
	padding-top: 20px;
	margin-bottom: -30px;
}

.dive-p {
	padding: 0 10px;
}
