.info-wrap {
	width: 100%;
	text-align: center;
}

.info-header {
	font-size: 50px;
	font-weight: 300;
}

.info-table-wrap {
	margin-top: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.info-table {
	height: fit-content;
	width: 60%;
	max-width: 1200px;
	border-radius: 2rem;

	background: rgba(255, 255, 255, 0.3);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
}

.info-search {
	margin-top: 20px;
}

.info-head {
	font-size: 80px;
	font-weight: 300;
	margin-bottom: 0;
}

/* table */
.categories-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.cat-wrap {
	height: 100px;
	width: 300px;
	font-size: 40px;
	font-weight: 300;
	margin: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;

	background: white;
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0),
		rgba(0, 0, 0, 0.1)
	);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

	/* background: linear-gradient(
		to right bottom,
		rgba(214, 214, 214, 0.3),
		rgba(0, 119, 182, 0.3)
	);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px; */
}

.cat-wrap:hover {
	background: linear-gradient(
		to right bottom,
		rgba(214, 214, 214, 0.5),
		rgba(0, 119, 182, 0.5)
	);
}

.dive-display-header {
	font-size: 50px;
	font-weight: 300;
	margin: 20px;
}

.indie-dive-wrap {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.individual-dive {
	margin: 2px;

	width: fit-content;
	cursor: pointer;
}

.selected-dive-wrap {
	position: fixed;
	padding: 20px;
	top: 50%;
	right: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 1rem;
	border: 1px solid black;
}

.btn-wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

/* dive box */
.dbox-info {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.dbox-head {
	font-size: 25px;
}

.dbox-dive {
	cursor: pointer;
	margin: 0 10px 10px 10px;
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.9),
		rgba(255, 255, 255, 0.1)
	);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
	padding: 8px;
	border-radius: 1rem;
}
.dbox-dive:hover {
	background: linear-gradient(
		to right bottom,
		rgba(61, 61, 61, 0.9),
		rgba(0, 0, 0, 0.5)
	);
	color: white;
}
.dbox-wrap {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.dbox-container {
	background: linear-gradient(
		to right bottom,
		rgba(214, 214, 214, 0.5),
		rgba(0, 119, 182, 0.3)
	);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
	margin: 5px;
	padding-top: 10px;
	min-width: 220px;
	border-radius: 1rem;
}

/* tw */
.twist-contain {
	display: flex;
	justify-content: center;
	align-items: center;
}
.twist-wrap {
	margin: 20px;
	border-radius: 2rem;
	/* background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.3),
		rgba(214, 214, 214, 0.5)
	);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px; */
}
.twist-cat-header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
	background: linear-gradient(
		to right bottom,
		rgba(214, 214, 214, 0.5),
		rgba(0, 119, 182, 0.3)
	);

	border-radius: 2rem;
	margin: 10px;
}

.tw-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 70px;
	width: 100px;
}

.hr {
	height: 70px;
}

.dbox-tw {
	display: flex;
	flex-direction: column;
}

@media (max-width: 777px) {
	.tw-btn {
		font-size: 10px;
		width: 50px;
	}
}
