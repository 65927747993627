@import url('colors.css');

/* Home */
.home-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* background-color: var(--dark); */
	background-image: url(../../img/waterback.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
	background-size: 130%;
}

.home-content {
	width: 80%;
	max-width: 1300px;
	height: 900px;
}

/* About */

.about-content {
	color: var(--testprimary);
	/* border-top: 15px solid var(--dark);
	border-bottom: 15px solid var(--dark); */
	width: 100%;
	margin-top: 300px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(
		to bottom right,
		var(--testlight),
		var(--testprimarylight)
	);
}

.abt-wrap {
	margin-top: 50px;
	width: 80%;
	max-width: 1300px;
}
.abt-content,
.abt-content-right {
	margin-bottom: 100px;
	width: 700px;
	border-radius: 20px;
	padding: 20px;
	position: relative;
	/* border-left: 3px solid var(--testprimary);
	border-right: 3px solid var(--testprimary); */
}
.abt-content-right {
	margin-left: auto;
	margin-right: 0;
}
.abt-question {
	font-size: 50px;
}
.abt-answer {
	font-size: 40px;
	font-weight: 200;
	color: var(--testdark);
}

/* navbar */
.HN-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home-nav {
	color: white;
	position: relative;
	margin-top: 20px;
	max-width: 1300px;
	height: 100px;
	display: flex;
}

.home-nav-dark {
	color: black;
	position: relative;
	margin-top: 20px;
	width: 80vw;
	max-width: 1300px;
	height: 100px;
	display: flex;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 2rem;
}

.HN-links {
	height: 100%;
	width: 100%;

	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.HN-link {
	letter-spacing: 1px;
	font-size: 20px;
	font-weight: 300;
	margin: 40px;
}
.HN-link:hover {
	text-decoration: underline;
}
.nav-burg {
	display: none;
}

.profile-top {
	position: absolute;
	left: 88%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100%;
	width: 120px;
}

.login-signup {
	cursor: pointer;
	text-decoration: underline;
}

.icon {
	cursor: pointer;
	font-size: 50px;
}

img {
	transition: 0.2s;
	width: 200px;
}
img:hover {
	width: 210px;
	transition: 0.2s;
}

.HN-logo {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 250px;
	position: absolute;
	z-index: 1;
}

/* landing */
.landing-wrap,
.options-wrap {
	margin-top: 200px;
	width: 100%;

	display: flex;
	flex-direction: row;
}

.landing-header {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.landing-head {
	font-size: 80px;
	font-weight: 300;
	color: white;
}

.large {
	font-size: 140px;
}

.landing-squares {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: fit-content;
	height: 550px;
}
.s-row {
	display: flex;
	flex-wrap: wrap;
}
.square {
	transition: 0.5s;

	cursor: pointer;

	height: 18vw;
	width: 18vw;
	max-height: 300px;
	max-width: 300px;
	margin: 5px;

	border-radius: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	font-size: 50px;
	font-weight: 200;
	color: black;
	background: rgba(255, 255, 255, 0.6);
}

.square:hover {
	transition: 0.5s;
	color: black;
	background-color: var(--testprimarylight);
}

/* Options */

.options-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.option {
	cursor: pointer;
	font-size: 40px;
	font-weight: 300;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	height: 100px;
	width: 200px;
	margin: 10px;

	background: var(--testsecondary);
	border: 15px solid var(--testsecondarylight);
	color: var(--testdark);

	/* background: white;
	background: linear-gradient(
		to right bottom,
		rgba(243, 243, 243, 0.3),
		rgba(226, 226, 226, 0.3)
	); */

	/* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}

.option:hover {
	color: var(--dark);
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 255, 0.8)
	);
}

.news-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 80px;
}

.news-box {
	height: 400px;
	width: 700px;
	background-color: black;
}

video {
	min-height: 100%;
	min-width: 1024px;

	width: 100%;
	height: auto;

	position: fixed;
	top: 0;
	left: 0;

	z-index: -99;

	opacity: 20%;
}

.video-div {
	background: black;
	min-height: 100%;
	min-width: 1024px;

	width: 100%;
	height: auto;

	position: fixed;
	top: 0;
	left: 0;

	z-index: -98;
}

/* footer */
.footer-content {
	width: 100%;
	height: 300px;
	background-color: var(--testprimary);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.footer-wrap {
	width: 80%;
	display: flex;
}

.footer-links {
	display: flex;
}
.footer-link {
	font-size: 60px;
	width: fit-content;
	margin: 20px;
	cursor: pointer;
}
.footer-text {
	margin-left: auto;
	margin-right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* temp */

.temp-text {
	margin-top: 30vh;
	text-align: center;
	font-size: 100px;
}

.temp-button-div {
	width: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.temp-button {
	text-align: center;
	font-size: 30px;
	border: 5px solid black;
	border-radius: 1rem;
	width: fit-content;
	padding: 10px;
	cursor: pointer;
}

.temp-button:hover {
	background-color: #0e9ebb;
	color: white;
}

.black-text {
	color: black;
}

.HN-logo-small {
	cursor: pointer;
	display: none;
	z-index: 1;
}

@media (max-width: 1630px) and (min-width: 1001px) {
	.home-content {
		height: 700px;
	}
	.home-wrap {
		background-size: auto 40%;
	}
	.home-content {
		/* background-color: yellow; */
	}
	.landing-wrap {
		/* background-color: green; */
		margin-top: 10vw;
	}
	.landing-squares {
		/* background-color: blue; */
	}

	.square {
		transition: 0s;
		font-size: 3vw;

		/* background-color: red; */
	}

	.landing-head {
		font-size: 5vw;
	}

	.large {
		font-size: 8vw;
	}

	.HN-container {
		/* background-color: greenyellow; */
	}

	.home-nav-dark,
	.home-nav {
		/* background-color: blueviolet; */
	}

	.login {
		display: none;
	}

	.HN-links {
		/* background-color: red; */
	}

	.HN-logo {
		display: none;
	}

	.HN-logo-small {
		display: flex;
		width: 20px;
		margin-left: 20px;
	}
}

@media (max-width: 1000px) {
	.home-wrap {
		background-size: auto 50%;
	}
	.HN-container {
		display: none;
	}

	.nav-burg {
		display: flex;
		width: 100%;
		align-items: center;
	}

	.logo-burg-img {
		width: 150px;
		background: rgba(255, 255, 255, 0.6);
		cursor: pointer;
	}

	.logo-burg {
		width: 100%;
		margin-top: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logo-burg-img:hover {
		width: 150px;
	}

	.burg {
		font-size: 50px;
		cursor: pointer;
		background: rgba(255, 255, 255, 0.6);
		border-radius: 3rem;
		position: absolute;
		left: 80%;
		top: 7%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
	}
	.home-content {
		height: 500px;
	}

	.landing-header {
		display: none;
	}

	.landing-wrap {
		margin-top: 100px;
	}

	.landing-squares {
		width: 100%;
		/* background-color: red; */
	}

	.s-row {
		flex-direction: column;
	}

	.square {
		width: 80vw;
		height: 100px;
		margin-bottom: 50px;
	}

	.abt-content,
	.abt-content-right {
		width: 100%;
	}

	.abt-answer {
		font-size: 20px;
	}

	.footer-wrap {
		flex-direction: column;
	}

	.footer-text {
		text-align: center;
		margin-left: 50;
		margin-right: auto;
	}

	.footer-links {
		width: 100%;
		justify-content: center;
	}

	/* burg */

	body.active-modal {
		/* overflow-y: hidden; */
	}

	.burg-active {
		position: absolute;
		right: 10%;
		margin-top: 400px;
		display: flex;

		width: 80%;
		height: fit-content;
	}

	.burg-option-wrap {
		position: inherit;
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
		height: fit-content;
	}

	.burg-option {
		background-color: var(--testlight);
		font-size: 25px;
		width: 100%;
		text-align: center;
		padding-top: 50px;
		padding-bottom: 50px;
		height: fit-content;
	}
}
