@import url('colors.css');

Header {
	text-align: center;
}

.meets-wrap {
	width: 100%;
	display: flex;
}

.meet-header {
	font-size: 70px;
	font-weight: 300;
	margin-bottom: 0;
}

.meet-link-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.meet-link,
.meet-link-back {
	cursor: pointer;
	font-size: 40px;
	font-weight: 300;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	height: 100px;
	width: 70%;
	max-width: 400px;
	margin: 10px;
	border-radius: 0.5em;

	background: white;
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.3),
		rgba(255, 255, 255, 0.3)
	);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.meet-link:hover {
	color: var(--dark);
	background: linear-gradient(
		to right bottom,
		rgba(187, 187, 187, 0.3),
		rgba(145, 145, 145, 0.3)
	);
}
.meet-link-back {
	height: 50px;
	width: 200px;
	font-size: 20px;
}
.meet-link-back:hover {
	background: linear-gradient(
		to right bottom,
		rgba(255, 106, 106, 0.3),
		rgba(184, 0, 0, 0.3)
	);
}

.disclaimer {
	text-align: center;
	color: red;
	font-size: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.dis-body {
	height: fit-content;
	max-width: 500px;
	color: black;
	font-size: 13px;
}
.meets-h2 {
	font-size: 40px;
	font-weight: 300;
	margin-top: 0px;
}

.meets-div {
	width: 50%;
	height: fit-content;

	/* border: 1px solid black; */
}

.meets-head {
	width: 100%;
	text-align: center;
}

.meets-data {
	margin: 20px;
}

.meets-create {
	padding-top: 40px;
	display: flex;
	justify-content: center;
}

.meet-create-btn {
	padding: 40px;
}

.meet-list-wrap {
	display: flex;
	flex-wrap: wrap;
	height: 700px;
	overflow-y: scroll;
}
.qm-modal-container {
	overflow: scroll;
}
/* future meets */
a {
	font-size: 30px;
}

.meet-opt {
	border: 1px solid black;
	cursor: pointer;

	padding: 10px;
	width: 300px;
	margin: 20px;
	list-style-type: none;
	text-decoration: none;

	flex-wrap: wrap;
}

.meet-opt-single {
	text-decoration: none;
	color: black;
}

.meet-opt:hover {
	background-color: grey;
}

.input-qm {
	border: 1px solid black;
}

.qm-header {
	font-size: 30px;
}

.center-wrap {
	margin-top: 75px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* run meet */
.center {
	text-align: center;
}
.add-btn,
.check-btn {
	margin-bottom: 30px;
	cursor: pointer;
	text-align: center;

	border-radius: 1rem;
	background: linear-gradient(
		to right bottom,
		rgba(214, 214, 214, 0.3),
		rgba(0, 119, 182, 0.3)
	);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
}
.add-btn {
	padding: 10px;
}
.check-btn {
	margin-top: 15px;
	padding: 10px;
}
.back-btn {
	padding: 10px;
	width: 50px;
	background: linear-gradient(
		to right bottom,
		rgba(255, 106, 106, 0.3),
		rgba(184, 0, 0, 0.3)
	);
}
.margin {
	margin: 20px 0 20px 0;
}
.close-btn {
	cursor: pointer;
	border-radius: 2rem;
	margin-top: 20px;
	width: 50px;
	text-align: center;
	padding: 10px;
	background: linear-gradient(
		to right bottom,
		rgba(255, 106, 106, 0.3),
		rgba(184, 0, 0, 0.3)
	);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
}
.close-btn:hover {
	background: linear-gradient(
		to right bottom,
		rgba(255, 106, 106, 0.7),
		rgba(184, 0, 0, 0.8)
	);
}
.run-meet-wrap {
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.run-meet-head {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.run-meet-header {
	font-size: 40px;
	font-weight: 300;
}

.foot-btns {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.smaller-btn {
	font-size: 20px;
	height: 50px;
	width: 200px;
	border-radius: 1rem;

	background: linear-gradient(
		to right bottom,
		rgba(122, 142, 255, 0.3),
		rgba(13, 0, 126, 0.3)
	);
}
.smaller-btn {
	margin-top: 0px;
}

.dive-valid {
	font-size: 17px;
	font-weight: 300;
}

.dive-correct {
	margin-top: 10px;
	text-align: center;
}

.dive-display {
	text-align: center;
	margin: 10px;
	font-weight: 300;
	font-size: 20px;
}

.check-wrap {
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: -57px;
}

.message {
	margin-bottom: 25px;
}

/* meet run */

.run-meet-body {
	width: 80vw;
	max-width: 1200px;
}

.rm-top {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.rm-top-box {
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.rmmid {
	text-align: center;
}

.rmright {
	text-align: end;
}

.rm-mid {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.rm-mid-box {
	width: 20%;
	text-align: center;
	border: 1px solid black;
}

.rm-mid-info {
	padding: 0 5px 0 5px;
}

.rmunderline {
	text-decoration: underline;
}

/* judge score box */
.ds-judges {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.ds-judge-input {
	margin: 0 20px 0 20px;
}

.ds-box {
	border: 1px solid black;
	text-align: center;
	height: 30px;
	color: red;
}

/* score buttons */

.ds-row {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ds-button,
.ds-shaded,
.ds-submit,
.ds-next,
.ds-endmeet {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	font-weight: 300;
	border-radius: 10px;
	height: 50px;
	width: 50px;
	margin: 7px;

	background: linear-gradient(
		to right bottom,
		rgba(214, 214, 214, 0.3),
		rgba(0, 119, 182, 0.3)
	);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
	user-select: none;
}

.ds-back {
	background-color: red;
	width: 100px;
}

.ds-shaded {
	background: linear-gradient(
		to right bottom,
		rgba(187, 187, 187, 0.3),
		rgba(2, 4, 116, 0.3)
	);
}

.ds-button:hover,
.ds-back:hover,
.ds-shaded:hover {
	background: linear-gradient(
		to right bottom,
		rgba(0, 0, 0, 0.3),
		rgba(0, 0, 0, 0.5)
	);
}

.ds-button:active,
.ds-back:active,
.ds-shaded:active {
	color: white;
	background: linear-gradient(
		to right bottom,
		rgba(0, 0, 0, 0.6),
		rgba(0, 0, 0, 0.8)
	);
}

.ds-submit,
.ds-next,
.ds-endmeet,
.ds-fill {
	cursor: pointer;
	border-radius: 10px;
	width: 50%;
	margin-top: 20px;
	height: 50px;
	text-align: center;
	color: white;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
}

.ds-submit {
	color: black;
}

.ds-submit:hover {
	background: linear-gradient(
		to right bottom,
		rgba(0, 0, 0, 0.3),
		rgba(2, 4, 116, 0.3)
	);
}

.ds-submit:active {
	color: white;
	background: linear-gradient(
		to right bottom,
		rgba(0, 0, 0, 0.6),
		rgba(2, 4, 116, 0.8)
	);
}

.ds-total-dis {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	font-weight: 300;
}

.ds-next {
	color: black;
	background: linear-gradient(
		to right bottom,
		rgba(171, 255, 192, 0.3),
		rgba(0, 219, 37, 0.3)
	);
}
.ds-next:hover {
	background: linear-gradient(
		to right bottom,
		rgba(171, 255, 192, 0.6),
		rgba(0, 219, 37, 0.8)
	);
}
.ds-next:active {
	color: white;
	background: linear-gradient(
		to right bottom,
		rgba(171, 255, 192, 0.6),
		rgba(2, 116, 21, 0.8)
	);
}

.ds-endmeet {
	color: black;
	background-color: red;
	text-decoration: none;
}
.ds-endmeet:hover {
	background-color: rgb(95, 0, 0);
	text-decoration: none;
}
.ds-endmeet:active {
	color: white;
	background-color: rgb(36, 0, 0);
}

.ds-foot-btns {
	display: flex;
}

.me-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.me-wrap-2 {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.me-divers-wrap {
	width: fit-content;
	max-width: 1565px;
	border-radius: 2rem;
	background-color: var(--light);
	padding: 20px 0 20px 0;

	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
}
.EM-DD-wrap {
	display: flex;
}

@media (max-width: 1565px) {
	.me-wrap-2 {
		width: 80vw;
	}

	.EM-DD-wrap {
		display: flex;
		/* width: 500px;
	height: 600px;
	overflow: scroll; */
	}
	.me-divers-wrap {
		display: flex;
		width: 100%;
		overflow: auto;
		float: left;
		position: relative;

		margin-left: -5px;
	}
}
.me-diver-wrap {
	width: 170px;
	padding: 20px;
	margin: 0 20px 0 20px;
	border-radius: 2rem;

	box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 10px, rgba(0, 0, 0, 0.24) 0px 5px 10px;
}
.me-head {
	margin-top: 50px;
	font-size: 50px;
	font-weight: 300;
}

.me-name {
	font-size: 25px;
}
.me-txt {
	font-size: 20px;
	text-align: center;
}
.me-txt-sml {
	font-size: 14px;
}
.me-txt-bld {
	font-size: 15px;
	font-weight: 500;
}
.me-score {
	display: flex;
	flex-direction: row;
}
.me-dive-wrap {
	margin: 10px 0 10px 0;
}
.me-results-download {
	margin: 30px 0 40px 0;
}
.download-btn {
	cursor: pointer;
	border-radius: 2rem;
	padding: 15px;
	text-align: center;

	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.24) 0px 2px 4px;
	background: linear-gradient(
		to right bottom,
		rgba(0, 119, 182, 0.3),
		rgba(0, 119, 182, 0.7)
	);
}
.download-btn:hover {
	background: linear-gradient(
		to right bottom,
		rgba(0, 119, 182, 0.7),
		rgba(0, 119, 182, 0.9)
	);
}
.download-btn:active {
	color: white;
	background: linear-gradient(
		to right bottom,
		rgba(0, 0, 0, 0.7),
		rgba(0, 0, 0, 0.9)
	);
}
/* modal */
/* Modal */
body.active-modal {
	overflow-y: hidden;
}

.dc-modal-container,
.dc-modal-background {
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	overflow: scroll;
}

.dc-modal-background {
	background: rgba(49, 49, 49, 0.8);
	z-index: 99;
}

.dc-modal-content {
	position: absolute;
	top: 0;
	margin-top: 50px;
	left: 50%;
	transform: translate(-50%, 0);
	line-height: 1.4;
	background: #f1f1f1;
	padding: 14px 28px;
	border-radius: 10px;
	width: fit-content;
	z-index: 1000;
	overflow: scroll;
}
.print-div {
	position: absolute;
	left: 0;
	top: -10000px;
	z-index: -1;
}

/* cell */
@media (max-width: 1300px) and (min-width: 700px) {
}

@media (max-width: 699px) {
	.rm-top {
		width: 100%;
		flex-direction: column;
	}
	.rm-top-box {
		flex-direction: row;
		width: 100%;
	}
	.rm-top-info {
		width: 100%;
		text-align: center;
	}
	.rmmid {
		font-size: 20px;
		font-weight: 400;
	}
	.rm-mid {
		flex-direction: column;
	}
	.rm-mid-box {
		width: 100%;
		margin: 2px;
		border-radius: 1rem;
	}
}

.dc-modal {
	overflow: hidden;
}
