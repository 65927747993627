@import url('colors.css');

.modal-container,
.modal-background {
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
}

.modal-background {
	background: rgba(49, 49, 49, 0.8);
	z-index: 99;
}

.modal-content {
	position: absolute;
	top: 0;
	margin-top: 50px;
	left: 50%;
	transform: translate(-50%, 0);
	line-height: 1.4;
	background: #f1f1f1;
	padding: 14px 28px;
	border-radius: 10px;
	width: 80%;
	max-width: 600px;
	z-index: 1000;
}
.rules {
	height: fit-content;
	max-height: 500px;
	overflow-y: scroll;
}

.modal-close {
	cursor: pointer;
	color: var(--dark);
	font-size: 1rem;
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px 7px;
	background-color: white;
	border: 3px solid var(--primary);
	border-radius: 1rem;
}

.dm-display {
	display: flex;
	flex-direction: row;
}

.modal-header {
	font-weight: 300;
	font-size: 50px;
}
