@import url('colors.css');

body {
}

.page-bottom-buffer {
	padding-bottom: 100px;
}

.cursor {
	cursor: pointer;
}

.underline {
	text-decoration: underline;
}
