@import url('colors.css');

/* General */
.page-wrap {
}

.page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.h3 {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 40px;
	font-weight: 300;

	margin-top: -10px;
}

.header {
	font-weight: 300;
	font-size: 40px;
}

.input-div {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.form-label {
	font-size: 6mm;
	font-weight: 300;
}

.form-input {
	padding: 0.9rem 2.8rem;
	font-weight: 400;
	border-radius: 1rem;
	border: 1px solid black;
	font-size: 1rem;
	margin: 0.5rem 0.5rem 0 0;
	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(255, 255, 255, 0.8);
}

.form-button {
	cursor: pointer;
	font-size: 40px;
	font-weight: 300;

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	height: 100px;
	width: 50px;
	margin: 10px;
	border-radius: 1em;

	background: white;
	background: rgba(255, 255, 255, 0.3);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.form-button:hover {
	color: white;
	background: rgba(104, 191, 231, 0.5);
}

.button-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.form-button:active {
	background-color: var(--light);
}

.next {
	border-color: var(--dark);
	font-size: 7mm;
	padding: 1rem 5rem;
}

.back {
	border-color: var(--primary);
	font-size: 20px;
	height: 50px;
	width: 75px;
}

.header-p {
	margin-top: -30px;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	font-size: 17px;
	font-weight: 300;
}

.submit-anyway {
	border-color: var(--red);
	font-size: 20px;
	height: 35px;
	width: fit-content;
}

.submit-anyway:hover {
	background: linear-gradient(
		to right bottom,
		rgba(255, 49, 49, 0.8),
		rgba(255, 46, 46, 0.8)
	);
}

/* DiverInfoSix */
.modal-but-div {
	display: flex;
}
.modal-button {
	cursor: pointer;
	font-size: 16px;
	font-weight: 400;

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	height: 40px;
	width: 60px;
	margin: 10px;
	border-radius: 0.5rem;

	background: white;
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.3),
		rgba(255, 255, 255, 0.3)
	);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.modal-button:hover {
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 255, 0.8)
	);
}

.modal-button:active {
	background-color: var(--light);
}

/* EnterDiveSix */
body.active-modal {
	overflow-y: hidden;
}

.dive-valid,
.dive-invalid {
	width: 100%;
	padding: 4px;
	margin: 4px;
	border-radius: 0.5rem;
	font-weight: 400;
	font-size: 20px;
}

.dive-invalid {
	color: var(--red);
}

.dive-correct,
.dive-error {
	font-size: 6mm;
	font-weight: 400;
	text-decoration-line: underline;
}
.dive-error {
	color: var(--red);
	padding: 10px;
}

.dive-correct {
	color: green;
}

/* eleven dive */
.eleven-form-buttons {
	font-size: 50px;
	color: white;
	display: flex;
	justify-content: center;
	padding: 20px;
}

.eleven-form-arrow {
	padding: 20px;
	cursor: pointer;
}
.eleven-form {
	border: 5px solid white;
	border-radius: 2rem;
	padding: 20px;
}

.opt-container {
	display: flex;
	justify-content: center;
}

.opt-label {
	width: fit-content;
	margin-top: 10px;
	margin-left: 10px;
	padding: 4px;
	font-size: 20px;
	font-weight: 300;
}

.toggle {
	--width: 75px;
	--height: calc(var(--width) / 2);
	--border-radius: calc(var(--height) / 2);

	display: flex;
	cursor: pointer;
	justify-content: center;
	z-index: 0;
}

.toggle__input {
	display: none;

	z-index: 0;
}

.toggle__fill {
	margin-top: 10px;
	margin-bottom: 10px;
	position: relative;
	width: var(--width);
	height: var(--height);
	border-radius: var(--border-radius);
	background: var(--light);
	transition: background 0.2s;
}

.toggle__input:checked ~ .toggle__fill {
	background: var(--dark);
}

.toggle__fill::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: var(--height);
	width: var(--height);
	background: #ffffff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	border-radius: var(--border-radius);
	transition: transform 0.2s;
}

.toggle__input:checked ~ .toggle__fill::after {
	transform: translateX(var(--height));
}

.is-optional {
	text-decoration-line: underline;
}

.header-error {
	padding: 10px;
}

.round-container {
	border: 5px solid white;
	width: 100%;
	min-width: 300px;
	max-width: 400px;
	border-radius: 2rem;
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.round-head {
	display: flex;
	justify-content: center;
	font-weight: 300;
	font-size: 30px;
	margin: 0;
}

.dive-div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 10px;
}

.optional-div,
.voluntary-div {
	width: 90%;
	border-radius: 1rem;
}

.voluntary-div {
	border: 3px solid var(--dark);
}

.change-dd {
	border-color: red;
	padding: 10px;
}

.p {
	font-size: 30px;
	font-weight: 300;
}

/* create home */
.home-buttons-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-btn {
	cursor: pointer;
	font-size: 40px;
	font-weight: 300;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	height: 100px;
	width: 200px;
	margin: 10px;
	border-radius: 0.5em;

	background: white;
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.3),
		rgba(255, 255, 255, 0.3)
	);

	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.home-btn:hover {
	color: var(--dark);
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 255, 0.8)
	);
}
